.footer{
    padding: 40px 300px;
    display: grid;
    place-items: center;
    color: #e5e5e5;
}

.footer a{
    padding-left: 10px;
}

