.bg-image{
background:linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
url(../../images/image1.webp);
height:89vh;
width: auto;
}

.cent{
  display: flex;
  justify-content: center;
  align-items: center;
}

.cent h1{
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%);
  /* margin: 200px 100px; */
  color: #fff;
}