.navbar-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 75px;
    background-color: rgb(8,112,157);
    color: #fff;
}

.navbar-container a img{
    height: 70px;
}

.nav-ul{
    display: flex;
    justify-content: flex-end;
    list-style: none;
    width: 75%;
    gap: 50px;
}

.nav-items a{
    text-decoration: none;
    color: #fff;
    font-size: 17px;
    cursor: pointer;
    transition: .9s;
    transition: .9s;
}

.nav-items a:hover{
    color: #000;
    transition: .9s;
}

.nav_items_btn a{
    text-decoration: none;
    color: #fff;
    font-size: 17px;
    cursor: pointer;
    border: 1px solid #fff;
    padding: 6px 25px;
    margin-left: 30px;
    border-radius: 10px;
    transition: .5s;


}

.nav_items_btn a:hover{
    background-color: #000;
    transition: .5s;
}

.mobile-menu-icon{
    display: none;
}

@media screen and (max-width: 868px){

    /* .logo{
        display: flex;
        position: absolute;
        top: 15px;
        left: 35px;
    } */

    .nav-ul{
        display: none;
    }

    .nav-ul-mobile{
        position: absolute;
        display: block;
        list-style: none;
        left: 0;
        top: 75px;
        transition: all 0.5s ease-out;
        width: 100%;
    }

    .nav-items a{
        color: #fff;
        text-align: center;
        gap: 25px;
        width: 100%;
        transition: all 0.5s ease-out;
    }

    .mobile-menu-icon{
        padding: 0;
        margin: 0;
        display: block;
        position: absolute;
        font-size: 25px;
        color: #fff;
        background: #000;
        border: none;
        outline: none;
        top: 15px;
        right: 25px;
    }
}